import React, { useState, useEffect, useRef } from 'react'
import Recaptcha from "react-google-recaptcha";
import { arrayBufferToBinaryString } from 'blob-util'

const RECAPTCHA_KEY = '6LcVY-YZAAAAAI78Ib2J0lt7oWTWl8H6J8GrnMpc';

function Form ({name, confirmation}) {
  const [state, setState] = useState({})
  const [formName, setFormName] = useState(name)
  const [recipient, setRecipient] = useState('WebsiteGeneralEnquiries@concordltd.com')
  const [subject, setSubject] = useState('')
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const recaptchaRef = useRef(null)

  const formOptions = [
    'Merchant Services',
    'Manager Services',
    'Investor Services',
    'Potential Investors',
    'General Enquiries'
  ]

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleChangeFormName = (e) => {
    setFormName( e.target.value )
  }

  const updateFormSubject = (newFormName) => {
    setSubject( `${newFormName.charAt(0).toUpperCase() + newFormName.slice(1)} email received from Concord website` )
  }

  const updateRecipientEmail = (newFormName) => {
    const newRecipient = (() => {
      switch(newFormName) {
        case 'Merchant Services':
          return 'WebsiteMerchantServices@concordltd.com'
        case 'Potential Investors':
          return 'WebsitePotentialInvestors@concordltd.com'
        case 'Investor Services':
          return 'WebsiteInvestorServices@concordltd.com'
        case 'Manager Services':
          return 'WebsiteManagerServices@concordltd.com'
        case 'careers':
          return 'WebsiteCareersPage@concordltd.com'
        default:
          return 'WebsiteGeneralEnquiries@concordltd.com'
      }
    })()

    setRecipient(newRecipient)
  }

  const isValidFileType = (fName) => {
    const extensionLists = ['docx', 'doc', 'pdf']
    return extensionLists.indexOf(fName.split('.').pop()) > -1
  }

  const getDataArray = (file, type) => {
    const reader = new FileReader()
    return new Promise(resolve => {
      reader.onload = ev => {
        const arrayBuffer = ev.target.result
        const blob = arrayBufferToBinaryString(arrayBuffer)

        resolve(blob)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const handleAttachment = async (e) => {
    const file = e.target.files[0]

    if (!file) return null

    const fileName = file.name
    const fileSize = file.size
    const fileType = file.type
    const fileSizeMb = Math.round((fileSize / 1024));

    if (fileSizeMb > 1024) {
      alert('Please upload a file 1Mb or less.')
      e.target.value = null
      return null
    }

    if (!isValidFileType(fileName)) {
      alert('Please upload a valid file type (.docx, .doc, .pdf)')
      e.target.value = null
      return null
    }

    const dataFile = await getDataArray(file, fileType)

    setState({ ...state, file: {
      data: dataFile,
      name: fileName,
      type: fileType
    } })
  }

  const handleRecaptcha = () => {
    let recaptchaValue = recaptchaRef.current.getValue();

    setState({ ...state, "g-recaptcha-response": recaptchaValue })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    fetch('/.netlify/functions/send-email', {
      method: 'POST',
      body: JSON.stringify({
        'form-name': formName,
        'subject': subject,
        'recipient-email': recipient,
        ...state,
      }),
    })
    .then((response) => {
      if (!response.ok) { throw response }

      setComplete(true)
    })
    .catch((error) => {
      alert('An error occurred')
    })
    .finally(() => setLoading(false))
  }

  useEffect(() => {
    updateFormSubject(formName)
    updateRecipientEmail(formName)
  }, [formName])

  return (
    <>
      {(loading === true) &&
        <div className={`form-message t-h3 text-body t-align-c ${name === 'contact' ? 't-color-white' : 't-color-blue'}`}>
          <p>Please wait while we process your submission.</p>
        </div>
      }
    
      {(complete === true) &&
        <div className={`form-message t-h3 text-body t-align-c ${name === 'contact' ? 't-color-white' : 't-color-blue'}`}>
          <p>Thank you for your submission.</p>
        </div>
      }

      <form
        name={formName}
        method="post"
        onSubmit={handleSubmit}
        className="form"
      >
        <input type="hidden" name="form-name" value={formName} />
        <input type="hidden" name="subject" value={subject} />
        <div hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </div>
        {name === 'careers' ? 
          <>
            <div className="form-single-column">
              <input
                type="text"
                name="name"
                placeholder="Name*"
                required
                className="form-input"
                onChange={handleChange}
              />
              <input
                type="text"
                name="phone"
                placeholder="Phone Number"
                className="form-input"
                onChange={handleChange}
              />
              <input
                type="email"
                name="email"
                placeholder="Email Address*"
                required
                className="form-input"
                onChange={handleChange}
              />
              <div className="form-file-wrap">
                <input
                  type="file"
                  name="file"
                  required
                  onChange={handleAttachment}
                />
              </div>
              <div className="form-submit form-submit--center">
                <div className="form-recaptcha">
                  <Recaptcha
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_KEY}
                    onChange={handleRecaptcha}
                  />
                </div>
                <button type="submit" className="button t-uc">
                  Submit
                </button>
              </div>
            </div>
          </>
        : name === 'contact' ? 
          <>
            <div className="form-single-column">
              <input
                type="text"
                name="name"
                placeholder="Name*"
                required
                className="form-input--alt"
                onChange={handleChange}
              />
              <input
                type="text"
                name="phone"
                placeholder="Phone Number"
                className="form-input--alt"
                onChange={handleChange}
              />
              <input
                type="email"
                name="email"
                placeholder="Email Address*"
                required
                className="form-input--alt"
                onChange={handleChange}
              />
              <input
                type="text"
                name="company"
                placeholder="Company"
                className="form-input--alt"
                onChange={handleChange}
              />
              <div className="select-wrap--alt">
                <select
                  name="department"
                  required
                  className="form-select--alt"
                  onChange={handleChangeFormName}
                  defaultValue=""
                >
                  <option value="" disabled>Select Department*</option>
                  {formOptions.map((option, index) => {
                    return (
                      <option key={`option-${index}`}>{option}</option>
                    )
                  })}
                </select>
                <div className="select-icon">
                  <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9678 1.35449L2.32261 11.9997L12.9678 22.6448" stroke="white" strokeWidth="2"/>
                  </svg>
                </div>
              </div>
              <div className="form-textarea-wrap">
                <label className="form-label">Message</label>
                <textarea
                  name="message"
                  className="form-textarea--alt"
                  onChange={handleChange}
                  rows="6"
                />
              </div>
              <div className="form-submit form-submit--center">
                <div className="form-recaptcha">
                  <Recaptcha
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_KEY}
                    onChange={handleRecaptcha}
                    theme="dark"
                  />
                </div>
                <button type="submit" className="button t-uc t-color-white">
                  Submit
                </button>
              </div>
            </div>
          </>
        :
          null
        }
      </form>
    </>
  )
}

export default Form