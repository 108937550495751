import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import PageHeader from '../components/pageHeader/pageHeader'
import Form from '../components/form/form'
  
export const query = graphql`
  query CareersQuery {
    prismic {
      allCareerss {
        edges {
          node {
            title
            introduction_text
            background_video {
              ... on PRISMIC__ImageLink {
                _linkType
                url
                width
                height
              }
              ... on PRISMIC__FileLink {
                _linkType
                url
              }
            }
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const Partners = ({ data }) => {
  const doc = data.prismic.allCareerss.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title="Careers" />
      <PageHeader
        title={doc.node.title}
        text={doc.node.introduction_text}
        id="overview"
        background={doc.node.background_video}
        button={"Upload your CV"}
      />
      <div className={`section section-white`}>
        <div className="cols-wrapper">
          <div className="cols">
            <div className={`col-12-md col-12-lg`}>
              <div className="form-wrapper">
                <Form
                  name="careers"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Partners
